.userlist td > a {
  text-decoration: none;
}
.tableheader th {
  padding: 15px;
}

.usericon {
  border: 1px solid #cccccc;
  max-width: 100px;
  min-width: 100%;
  min-height: 90px;
  max-height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.user-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */
}

/* .container {
  background-color:white;
  border-color:#808080;
  border-style:solid;
  border-width:1px;
  cursor:pointer;
}

.container:hover {
  background-color:#2C2B78;
} */

/* .sidebaritem {
  color: unset !important;
} */


.sidebar > a{
  background-color: blue !important;
  color: white !important;
}


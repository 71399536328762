.storeheader{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    /* aspect-ratio: 1 / 1; */
    width: 100%;   
    /* cursor: pointer; */
    /* border-radius: 5px;
    border: 1px solid #cccccc; */
    
}

.storeheadercontent{

    /* border-radius: 5px; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* margin: auto; */
    /* z-index: 2; */
    /* position: absolute; */
    max-height: 300px;
    padding: 20px;
    margin: auto;
    max-width: 1024px;
    width: 90%;
    /* margin-top: auto;
    margin-bottom: auto; */
    /* left: auto;
    right: auto; */

    /* border: 1px solid #ff0000; */
    /* background-color: rgba(4, 15, 73, 0.7); */
    color: #ffffff;
}

.footerlist{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footerlist li{
    margin: 20px 0;
}


.productcategory_thumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 2 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}

.productcategory_label_container{
    padding: 10px;
}

.productcategory_label{
    font-weight: bold !important;
}

.product_container{
    position: relative;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    /* padding: 5px; */
}
.product_info_container{
    /* background-color: #F8F8F8; */
    position: relative;
    padding: 5px;
    padding-top: 15px;
    /* border: 1px solid #ff0000; */
}

.product_thumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 2 / 1.5;
    width: 100%;   
    cursor: pointer;
    /* border-radius: 5px; */
    /* border: 1px solid #cccccc; */
}

.product_info_category_container{
    background-color: #ecfff6;
    border: 1px solid #cccccc;
    position: absolute;
    top: -10px;
    right: -1px;
    border-radius: 10px 0px 0px 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.cart_thumbnail{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    border-radius: 5px;
}

.agency_thumbnail{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    border-radius: 50%;
}

.stickytopcontent {
    position:-webkit-sticky;
    position: sticky;
    top: 0;
    /* min-height: 100px; */
    padding: 10px;
}
.stickycontent {
    position:-webkit-sticky;
    position: sticky;
    bottom: 0;
    /* min-height: 100px; */
    padding: 10px;
}

.totalfooter{
    display: flex;
    margin-right: 0;
    /* border: 1px solid #ff0000; */
}

.totalfooter > div{
    
    margin: 5px;
    justify-content: flex-end;
    align-items: center;
    /* text-align: center; */
    display: flex;
    /* border: 1px solid #ff0000; */
    vertical-align: middle;
    font-weight: bold;
}

.cartnumber{
    text-align:right;
    padding: 5px;
}

.subtotalnumber{
    width: 10vw;
    max-width: 300px;
    min-width: 140px;
}

.addressformheader{
    min-height: 50px;
}

.outofstock{
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #eeeeee;
    text-align: center;
    padding: 4px;
    /* box-shadow: 2px 2px #cccccc; */
    /* color: #ffffff; */
}

.container_browse{
    
}
.container_other{
    min-height: 500px;
    margin-top: 20px;
    margin-bottom: 50px;
}
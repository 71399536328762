.overviewcard{
    height: 100%;
    /* margin: 2px; */
    /* min-height: 100px; */
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.overviewcard .subcontainer{
    /* display: flex;
    padding: 10px; */
    /* border: 1px solid #ff0000; */
}

.overviewcard .container{
    display: flex;
    padding: 20px;
}

.overviewcard .iconmaincontainer{
    /* border: 1px solid #ff0000; */
    text-align: center;
    vertical-align: middle;
    /* height: 100%; */
}

.overviewcard .iconcontainer{
    padding: 10px;
    border-radius: 50%;
    background-color: #e5e5e5
}

.overviewcard .icon{
    font-size: 300%;
    color: #e5e5e5;
}

.overviewcard .icontranstaction{
    font-size: 200%;
    color: #ffffff;
}

.browse_container{
    display: flex;
    align-items: stretch;
    height: 100%;
    /* min-height: calc(100vh - 250px); */
    /* border: 1px solid #ff0000; */
    margin-bottom: 16px;
}
.browse_sidebar{
    width: 250px;
    background-color: #F8F8F8;
    /* border: 1px solid #ff0000; */
    padding: 20px;
}

.browse_content{
    flex-grow: 1;
    padding: 20px;
}
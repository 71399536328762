.productthumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}
.productthumbnailviewer{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
    height: 100%;
    min-Height: 250px;
}


.productagencycontainer{
    background-color: rgba(238,238,238, 1) !important;
    border: 1px solid rgba(112, 112, 112, 0.34) ;
    padding: 15px;
  }

.agencythumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

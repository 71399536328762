
.menubar {
  /* background-color: #e5e5e5; */
  border: 0px solid #e5e5e5;
  position: relative;
  /* width: calc(100% - 20px); */
  top: 0;
  /* position: fixed; */
  z-index: 5;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  /* padding: 20px; */
}

.subheader{
  background-color: #336699;
  color: #F8F8F8;
  padding: 10px;
  margin-bottom: 30px !important;
  /* height: 120px; */
  /* background-image: url('header-banner.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  border-top: 2px solid #eeeeee;
  border-bottom: 5px solid #cccccc;
  text-shadow: 1px 1px #cccccc;
  letter-spacing: 0.1em;

}

.subheader_user_container{
  width: 100%;
  border: 0px solid #ff0000;
  text-align: right;
}

.subheader_user{
  float: right;
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding: 5px;
  color: #000000;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.8)
}

.subheader a{
  color: #ffffff;
  text-decoration: none;
}
.bold {
  font-weight: bold;
}

.sysavatar{
  text-align: center;
  /* border: 1px solid #ff0000 !important; */
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.header_username { 
  width : 100%;
  max-width: 200px;
  overflow:hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  height: 1.2em; 
  color: #ffffff;
  text-decoration: none !important;
}

.header_flex_item { 
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .subheader {
    /* background-color: green; */
    text-align: center;
  }
}

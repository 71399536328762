@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
/* .container {
  background-color:white;
  border-color:#808080;
  border-style:solid;
  border-width:1px;
  cursor:pointer;
}

.container:hover {
  background-color:#2C2B78;
} */

/* .sidebaritem {
  color: unset !important;
} */


.Index_sidebar__1yBWv > a{
  background-color: blue !important;
  color: white !important;
}


.storeheader{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    /* aspect-ratio: 1 / 1; */
    width: 100%;   
    /* cursor: pointer; */
    /* border-radius: 5px;
    border: 1px solid #cccccc; */
    
}

.storeheadercontent{

    /* border-radius: 5px; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* margin: auto; */
    /* z-index: 2; */
    /* position: absolute; */
    max-height: 300px;
    padding: 20px;
    margin: auto;
    max-width: 1024px;
    width: 90%;
    /* margin-top: auto;
    margin-bottom: auto; */
    /* left: auto;
    right: auto; */

    /* border: 1px solid #ff0000; */
    /* background-color: rgba(4, 15, 73, 0.7); */
    color: #ffffff;
}

.footerlist{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footerlist li{
    margin: 20px 0;
}


.productcategory_thumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 2 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}

.productcategory_label_container{
    padding: 10px;
}

.productcategory_label{
    font-weight: bold !important;
}

.product_container{
    position: relative;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    /* padding: 5px; */
}
.product_info_container{
    /* background-color: #F8F8F8; */
    position: relative;
    padding: 5px;
    padding-top: 15px;
    /* border: 1px solid #ff0000; */
}

.product_thumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 2 / 1.5;
    width: 100%;   
    cursor: pointer;
    /* border-radius: 5px; */
    /* border: 1px solid #cccccc; */
}

.product_info_category_container{
    background-color: #ecfff6;
    border: 1px solid #cccccc;
    position: absolute;
    top: -10px;
    right: -1px;
    border-radius: 10px 0px 0px 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.cart_thumbnail{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    border-radius: 5px;
}

.agency_thumbnail{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    border-radius: 50%;
}

.stickytopcontent {
    position:-webkit-sticky;
    position: sticky;
    top: 0;
    /* min-height: 100px; */
    padding: 10px;
}
.stickycontent {
    position:-webkit-sticky;
    position: sticky;
    bottom: 0;
    /* min-height: 100px; */
    padding: 10px;
}

.totalfooter{
    display: flex;
    margin-right: 0;
    /* border: 1px solid #ff0000; */
}

.totalfooter > div{
    
    margin: 5px;
    justify-content: flex-end;
    align-items: center;
    /* text-align: center; */
    display: flex;
    /* border: 1px solid #ff0000; */
    vertical-align: middle;
    font-weight: bold;
}

.cartnumber{
    text-align:right;
    padding: 5px;
}

.subtotalnumber{
    width: 10vw;
    max-width: 300px;
    min-width: 140px;
}

.addressformheader{
    min-height: 50px;
}

.outofstock{
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #eeeeee;
    text-align: center;
    padding: 4px;
    /* box-shadow: 2px 2px #cccccc; */
    /* color: #ffffff; */
}

.container_browse{
    
}
.container_other{
    min-height: 500px;
    margin-top: 20px;
    margin-bottom: 50px;
}
.agencythumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}


.userprofile{
    width: 100%;
}

.aclheader{
    font-weight: bold;
    text-align: center;
    border: 1px solid #cccccc;
    background-color: #eeeeee;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.toggleaccess{
    cursor: pointer;
}

.aclborder{
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}
.overviewcard{
    height: 100%;
    /* margin: 2px; */
    /* min-height: 100px; */
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.overviewcard .subcontainer{
    /* display: flex;
    padding: 10px; */
    /* border: 1px solid #ff0000; */
}

.overviewcard .container{
    display: flex;
    padding: 20px;
}

.overviewcard .iconmaincontainer{
    /* border: 1px solid #ff0000; */
    text-align: center;
    vertical-align: middle;
    /* height: 100%; */
}

.overviewcard .iconcontainer{
    padding: 10px;
    border-radius: 50%;
    background-color: #e5e5e5
}

.overviewcard .icon{
    font-size: 300%;
    color: #e5e5e5;
}

.overviewcard .icontranstaction{
    font-size: 200%;
    color: #ffffff;
}

.productthumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}

.productthumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}

.sidebar_name{
    border: 1px solid #eeeeee;
    background-color: rgba(255,255,255,0.5) !important;
}
.userlist td > a {
  text-decoration: none;
}
.tableheader th {
  padding: 15px;
}

.usericon {
  border: 1px solid #cccccc;
  max-width: 100px;
  min-width: 100%;
  min-height: 90px;
  max-height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.user-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */
}

.agencythumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

.browse_container{
    display: flex;
    align-items: stretch;
    height: 100%;
    /* min-height: calc(100vh - 250px); */
    /* border: 1px solid #ff0000; */
    margin-bottom: 16px;
}
.browse_sidebar{
    width: 250px;
    background-color: #F8F8F8;
    /* border: 1px solid #ff0000; */
    padding: 20px;
}

.browse_content{
    flex-grow: 1;
    padding: 20px;
}
.myaccount_container{
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    /* min-height: calc(100vh - 250px); */
    /* border: 1px solid #ff0000; */
    margin-bottom: 16px;
}
.myaccount_sidebar{
    width: 250px;
    background-color: #F8F8F8;
    /* border: 1px solid #ff0000; */
    padding: 0px;
    min-height: 400px;
}

.myaccount_content{
    flex-grow: 1;
    padding: 20px;
    /* border: 1px solid #ff0000; */
}
.myaccount_profile{
    text-align: center;
    justify-content: center;
    align-items : center;
    /* border: 1px solid #ff0000; */
}

.myaccount_listitem > li > div > div, 
.myaccount_listitem > li > a > div {
    min-width: unset !important;
    padding-right: 10px;
}
.unitbutton{
    /* border: 1px solid #ff0000 */
}
.unitbutton > button{
    min-width: unset !important;
    /* padding: 0px 9px !important; */
    text-transform: none !important;
}


.productthumbnail{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1 / 1;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
}
.productthumbnailviewer{
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;   
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cccccc;
    height: 100%;
    min-Height: 250px;
}


.productagencycontainer{
    background-color: rgba(238,238,238, 1) !important;
    border: 1px solid rgba(112, 112, 112, 0.34) ;
    padding: 15px;
  }


.menubar {
  /* background-color: #e5e5e5; */
  border: 0px solid #e5e5e5;
  position: relative;
  /* width: calc(100% - 20px); */
  top: 0;
  /* position: fixed; */
  z-index: 5;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  /* padding: 20px; */
}

.subheader{
  background-color: #336699;
  color: #F8F8F8;
  padding: 10px;
  margin-bottom: 30px !important;
  /* height: 120px; */
  /* background-image: url('header-banner.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  border-top: 2px solid #eeeeee;
  border-bottom: 5px solid #cccccc;
  text-shadow: 1px 1px #cccccc;
  letter-spacing: 0.1em;

}

.subheader_user_container{
  width: 100%;
  border: 0px solid #ff0000;
  text-align: right;
}

.subheader_user{
  float: right;
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding: 5px;
  color: #000000;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.8)
}

.subheader a{
  color: #ffffff;
  text-decoration: none;
}
.bold {
  font-weight: bold;
}

.sysavatar{
  text-align: center;
  /* border: 1px solid #ff0000 !important; */
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.header_username { 
  width : 100%;
  max-width: 200px;
  overflow:hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  height: 1.2em; 
  color: #ffffff;
  text-decoration: none !important;
}

.header_flex_item { 
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .subheader {
    /* background-color: green; */
    text-align: center;
  }
}

body, html{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 90% !important;
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
/* .MuiTypography-body1{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
} */

.margintop{
  margin-top: 20px;
}
.papercontainer{
  background-color: rgba(245, 245, 245, 0.34) ;
  border: 1px solid rgba(112, 112, 112, 0.34) ;
  padding: 20px;
}

.fieldset {
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 5px;
  padding-bottom: 30px;
}

.fieldset > legend {
  border: 1px solid #dddddd;
  padding: 10px;
  padding-right: 20px;
  margin-left: -11px !important;
  border-radius: 5px;
  background-color: rgba(248, 248, 248, 0.9);
  /* width: calc(100% - 20px); */
  margin-left: 10px !important;
  font-weight: bold;
}
.menulist li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
  float: left;
}

.formid {
  border: 1px solid #ff0000;
  padding: 0px !important;
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
}

/* .menubar {
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  position: relative;
  padding-bottom: 10px !important;
} */
.menulist {
  position: relative;
}

.mapcontainer{
  position: relative;
  width: 100%;
  height: 400px;
}

.mapcontainer_module{
  position: absolute;
  width: 100%;
  height: 400px;
  left: 0;
  right: 0;
  top: 170px;
}

.mapcontainer_module_dummy{
  position: relative;
  width: 100%;
  height: 400px;
}

.mapsearch{
  position: absolute;
  top: 10px;
  left: 10px;
}


.searchresult{
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 5px;
}

.rbc-agenda-table td{
  background-color: #ffffff;
}

.rbc-agenda-table th{
  padding: 10px !important;
}

.mcmclogo{
  margin-bottom: 30px;
}
.font-link {
  font-family: 'Hanalei Fill', cursive;
}

.mainloading{
  display: flex;
  width: 100vw;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
  text-align: center;
}
body,
html {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Nunito", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


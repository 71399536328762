.aclheader{
    font-weight: bold;
    text-align: center;
    border: 1px solid #cccccc;
    background-color: #eeeeee;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.toggleaccess{
    cursor: pointer;
}

.aclborder{
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}
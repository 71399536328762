@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

body, html{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 90% !important;
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
/* .MuiTypography-body1{
  font-family: "Nunito","Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
} */

.margintop{
  margin-top: 20px;
}
.papercontainer{
  background-color: rgba(245, 245, 245, 0.34) ;
  border: 1px solid rgba(112, 112, 112, 0.34) ;
  padding: 20px;
}

.fieldset {
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 5px;
  padding-bottom: 30px;
}

.fieldset > legend {
  border: 1px solid #dddddd;
  padding: 10px;
  padding-right: 20px;
  margin-left: -11px !important;
  border-radius: 5px;
  background-color: rgba(248, 248, 248, 0.9);
  /* width: calc(100% - 20px); */
  margin-left: 10px !important;
  font-weight: bold;
}
.menulist li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
  float: left;
}

.formid {
  border: 1px solid #ff0000;
  padding: 0px !important;
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
}

/* .menubar {
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  position: relative;
  padding-bottom: 10px !important;
} */
.menulist {
  position: relative;
}

.mapcontainer{
  position: relative;
  width: 100%;
  height: 400px;
}

.mapcontainer_module{
  position: absolute;
  width: 100%;
  height: 400px;
  left: 0;
  right: 0;
  top: 170px;
}

.mapcontainer_module_dummy{
  position: relative;
  width: 100%;
  height: 400px;
}

.mapsearch{
  position: absolute;
  top: 10px;
  left: 10px;
}


.searchresult{
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 5px;
}

.rbc-agenda-table td{
  background-color: #ffffff;
}

.rbc-agenda-table th{
  padding: 10px !important;
}

.mcmclogo{
  margin-bottom: 30px;
}
.font-link {
  font-family: 'Hanalei Fill', cursive;
}

.mainloading{
  display: flex;
  width: 100vw;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.myaccount_container{
    display: flex;
    align-items: stretch;
    height: 100%;
    width: 100%;
    /* min-height: calc(100vh - 250px); */
    /* border: 1px solid #ff0000; */
    margin-bottom: 16px;
}
.myaccount_sidebar{
    width: 250px;
    background-color: #F8F8F8;
    /* border: 1px solid #ff0000; */
    padding: 0px;
    min-height: 400px;
}

.myaccount_content{
    flex-grow: 1;
    padding: 20px;
    /* border: 1px solid #ff0000; */
}
.myaccount_profile{
    text-align: center;
    justify-content: center;
    align-items : center;
    /* border: 1px solid #ff0000; */
}

.myaccount_listitem > li > div > div, 
.myaccount_listitem > li > a > div {
    min-width: unset !important;
    padding-right: 10px;
}